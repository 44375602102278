import {
  Network,
  Assignment,
  Dashboard,
  LandingPage,
  PolicyExplorer,
  IssueExplorer,
  KPCIExplorer,
  AccessDenied,
} from './views';

const routes = [
  { path: '/home', name: 'Home', component: LandingPage},
  { path: '/explore/policy', name: 'Policy Explorer', component: PolicyExplorer},
  { path: '/explore/kpci', name: 'KPCI Explorer', component: KPCIExplorer},
  { path: '/explore/issue', name: 'Issue Explorer', component: IssueExplorer},
  { path: '/explore/network', name: 'Network', component: Network},
  { path: '/assign/assignment', name: 'Assignment', component: Assignment},
  { path: '/assign/dashboard', name: 'Dashboard', component: Dashboard},
  { path: '/access_denied', name: 'Access Denied', component: AccessDenied},
];

export default routes;
