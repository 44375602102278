import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Excel_icon from '../Icons/download_icon/Excel_icon.png';
import "./style.css";
import ReactTable from "react-table";
// import 'react-table/react-table.css';
// import 'react-select/dist/react-select.css';
import _ from 'lodash';
import token from '../../token.js';
import { downloadCSV } from '../CSVExport/CSVExport.js';
import { post, postAll } from "../../services/http";
import RelevanceExplanation from './RelevanceExplanation';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';


function TwoColLine(props) {
  return (
    <div>
      <Row>
        <Col xs={props.split}>
          <strong>{props.name}:</strong>
        </Col>
        <Col>
          {props.field !== "01 Jan 1900" ? props.field : "-"}
        </Col>
      </Row>
    </div>
  );
}

class AssignmentProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recommended_policies: [],
      assigned_policies: [],
      popoverOpen: false,
      recommended_kpc: [],
      assigned_kpc: [],
      kpcButtonText: "Show all KPC",
      showAllKPC: false,

      recommended_kpci: [],
      assigned_kpci: [],
      kpciButtonText: "Show all KPCI",
      showAllKPCI: false,

      recommended_issues: [],
      assigned_issues: [],

      recommended_regulation: [],
      assigned_regulation: [],

      documentTab: 'policy', // can be 'policy','kpc','kpci','issue','risk taxonomy'
      policyTab: '1',   // for the policy tab recommendation/assignment
      kpcTab: '1',
      kpciTab: '1',
      issueTab: '1',
      regulationTab: '1',
      loadingLayerIsActive: false,
      activeTab: '1',
      highlight_yellow: "#fcff6d",
    };
  }

  componentDidMount() {
    let requests = [];
    requests.push({
      url: "/api/assign/get_policy_recommendations/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_policy_assignments/",
      data: { wprog_number: this.props.selectedWProg.value },
    });
    postAll(requests).then((result) => {
      this.setState({
        recommended_policies: result[0].data.ranked_policies,
        assigned_policies: result[1].data.assigned_policies,
        documentTab: 'policy',
        loadingLayerIsActive: false,
      });
    });
    this.setState({ loadingLayerIsActive: true });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      let requests = [];
      requests.push({
        url: "/api/assign/get_policy_recommendations/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_policy_assignments/",
        data: { wprog_number: nextProps.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_policies: result[0].data.ranked_policies,
          assigned_policies: result[1].data.assigned_policies,
          documentTab: 'policy',
          loadingLayerIsActive: false,
        });
      });
      this.setState({ loadingLayerIsActive: true });
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  document_toggle = (tab) => {
    if (this.state.documentTab !== tab) {
      this.setState({ documentTab: tab });
      switch (tab) {
        case 'policy':
          this.load_recommended_and_assigned_policies();
          break;
        case 'kpc':
          this.load_recommended_and_assigned_kpc();
          break;
        case 'kpci':
          this.load_recommended_and_assigned_kpci();
          break;
        case 'issue':
          this.load_recommended_and_assigned_issues();
          break;
        default:
          break;
      }
    }
  };

  selection_toggle = (doc, tab) => {
    switch (doc) {
      case 'policy':
        if (this.state.policyTab !== tab) {
          this.setState({ policyTab: tab });
        }
        break;
      case 'kpc':
        if (this.state.kpcTab !== tab) {
          this.setState({ kpcTab: tab });
        }
        break;
      case 'kpci':
        if (this.state.kpciTab !== tab) {
          this.setState({ kpciTab: tab });
        }
        break;
      case 'issue':
        if (this.state.issueTab !== tab) {
          this.setState({ issueTab: tab });
        }
        break;
      case 'risk_taxonomy':
        if (this.state.risk_taxonomyTab !== tab) {
          this.setState({ risk_taxonomyTab: tab });
        }
        break;
      default:
        break;
    }
  };

  load_recommended_and_assigned_policies = () => {
    if (this.state.select_value) {
      let requests = [];
      requests.push({
        url: "/api/assign/get_policy_recommendations/",
        data: { wprog_number: this.props.selectedWProg.value },
      }, {
        url: "/api/assign/get_policy_assignments/",
        data: { wprog_number: this.props.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_policy: result[0].data.ranked_policy,
          assigned_policy: result[1].data.assigned_policy,
          loadingLayerIsActive: false,
        });
      });
      this.setState({ loadingLayerIsActive: true });
    }
  };

  assign_policy_and_update = (policy_id) => {
    const status = 'assigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_policy_assignments/', {
      wprog_title,
      wprog_number,
      policy_id,
      status,
      comment,
      token: token.getToken(),
    })
      .then(() => {
        let requests = [];
        requests.push({
          url: "/api/assign/get_policy_recommendations/",
          data: { wprog_number: this.props.selectedWProg.value },
        }, {
          url: "/api/assign/get_policy_assignments/",
          data: { wprog_number: this.props.selectedWProg.value },
        });
        postAll(requests).then((result) => {
          this.setState({
            recommended_policies: result[0].data.ranked_policies,
            assigned_policies: result[1].data.assigned_policies,
          });
        });
      });
  };

  unassign_policy_and_update = (policy_id) => {
    const status = 'unassigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_policy_assignments/', {
      wprog_title,
      wprog_number,
      policy_id,
      status,
      comment,
      token: token.getToken(),
    })
      .then(() => {
        let requests = [];
        requests.push({
          url: "/api/assign/get_policy_recommendations/",
          data: { wprog_number: this.props.selectedWProg.value },
        }, {
          url: "/api/assign/get_policy_assignments/",
          data: { wprog_number: this.props.selectedWProg.value },
        });
        postAll(requests).then((result) => {
          this.setState({
            recommended_policies: result[0].data.ranked_policies,
            assigned_policies: result[1].data.assigned_policies,
          });
        });
      });
  };

  load_recommended_and_assigned_kpc = () => {
    if (this.props.selectedWProg) {
      let requests = [];
      requests.push({
        url: "/api/assign/get_kpc_recommendations_limited/",
        data: { wprog_number: this.props.selectedWProg.value },
      }, {
        url: "/api/assign/get_kpc_assignments/",
        data: { wprog_number: this.props.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_kpc: result[0].data.ranked_kpc,
          assigned_kpc: result[1].data.assigned_kpc,
          loadingLayerIsActive: false,
        });
      });
      this.setState({ loadingLayerIsActive: true });
    }
  };

  assign_kpc_and_update = (kpc_id) => {
    const status = 'assigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_kpc_assignments/', {
      wprog_title,
      wprog_number,
      kpc_id,
      status,
      comment,
      token: token.getToken(),
    }).then(() => {
      const new_recommended_kpc = _.filter(this.state.recommended_kpc, x => x.KPC_ID !== kpc_id);
      const new_assigned_kpc = this.state.assigned_kpc.concat(_.filter(this.state.recommended_kpc, x => x.KPC_ID === kpc_id));
      this.setState({
        recommended_kpc: new_recommended_kpc,
        assigned_kpc: new_assigned_kpc,
      });
    });
  };

  unassign_kpc_and_update = (kpc_id) => {
    const status = 'unassigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_kpc_assignments/', {
      wprog_title,
      wprog_number,
      kpc_id,
      status,
      comment,
      token: token.getToken(),
    }).then(() => {
      const new_recommended_kpc = this.state.recommended_kpc.concat(_.filter(this.state.assigned_kpc, x => x.KPC_ID === kpc_id));
      const new_assigned_kpc = _.filter(this.state.assigned_kpc, x => x.KPC_ID !== kpc_id);
      this.setState({
        recommended_kpc: new_recommended_kpc,
        assigned_kpc: new_assigned_kpc,
      });
    });
  };

  load_recommended_and_assigned_kpci = () => {
    if (this.props.selectedWProg) {
      let requests = [];
      requests.push({
        url: "/api/assign/get_kpci_recommendations_limited/",
        data: { wprog_number: this.props.selectedWProg.value },
      }, {
        url: "/api/assign/get_kpci_assignments/",
        data: { wprog_number: this.props.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_kpci: result[0].data.ranked_kpci,
          assigned_kpci: result[1].data.assigned_kpci,
          loadingLayerIsActive: false,
        });
      });
      this.setState({ loadingLayerIsActive: true });
    }
  };

  assign_kpci_and_update = (kpci_id) => {
    const status = 'assigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_kpci_assignments/', {
      wprog_title,
      wprog_number,
      kpci_id,
      status,
      comment,
      token: token.getToken(),
    }).then(() => {
      const new_recommended_kpci = _.filter(this.state.recommended_kpci, x => x.KPCI_ID !== kpci_id);
      const new_assigned_kpci = this.state.assigned_kpci.concat(_.filter(this.state.recommended_kpci, x => x.KPCI_ID === kpci_id));
      this.setState({
        recommended_kpci: new_recommended_kpci,
        assigned_kpci: new_assigned_kpci,
      });
    });
  };

  unassign_kpci_and_update = (kpci_id) => {
    const status = 'unassigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_kpci_assignments', {
      wprog_title,
      wprog_number,
      kpci_id,
      status,
      comment,
      token: token.getToken(),
    }).then(() => {
      const new_recommended_kpci = this.state.recommended_kpci.concat(_.filter(this.state.assigned_kpci, x => x.KPCI_ID === kpci_id));
      const new_assigned_kpci = _.filter(this.state.assigned_kpci, x => x.KPCI_ID !== kpci_id);
      this.setState({
        recommended_kpci: new_recommended_kpci,
        assigned_kpci: new_assigned_kpci,
      });
    });
  };

  load_recommended_and_assigned_issues = () => {
    if (this.props.selectedWProg) {
      let requests = [];
      requests.push({
        url: "/api/assign/get_issue_recommendations/",
        data: { wprog_number: this.props.selectedWProg.value },
      }, {
        url: "/api/assign/get_issue_assignments/",
        data: { wprog_number: this.props.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_issues: result[0].data.ranked_issues,
          assigned_issues: result[1].data.assigned_issues,
          loadingLayerIsActive: false,
        });
      });
      this.setState({ loadingLayerIsActive: true });
    }
  };

  assign_issue_and_update = (issue_id) => {
    const status = 'assigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_issue_assignments/', {
      wprog_title,
      wprog_number,
      issue_id,
      status,
      comment,
      token: token.getToken(),
    }).then(() => {
      let requests = [];
      requests.push({
        url: "/api/assign/get_issue_recommendations/",
        data: { wprog_number: this.props.selectedWProg.value },
      }, {
        url: "/api/assign/get_issue_assignments/",
        data: { wprog_number: this.props.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_issues: result[0].data.ranked_issues,
          assigned_issues: result[1].data.assigned_issues,
        });
      });
    });
  };

  unassign_issue_and_update = (issue_id) => {
    const status = 'unassigned';
    const wprog_title = this.props.selectedWProg.label;
    const wprog_number = this.props.selectedWProg.value;
    const comment = '';

    post('/api/secure/assign/post_issue_assignments/', {
      wprog_title,
      wprog_number,
      issue_id,
      status,
      comment,
      token: token.getToken(),
    }).then(() => {
      let requests = [];
      requests.push({
        url: "/api/assign/get_issue_recommendations/",
        data: { wprog_number: this.props.selectedWProg.value },
      }, {
        url: "/api/assign/get_issue_assignments/",
        data: { wprog_number: this.props.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          recommended_issues: result[0].data.ranked_issues,
          assigned_issues: result[1].data.assigned_issues,
        });
      });
    });
  };

  toggleAllKPC = () => {
    if (this.state.showAllKPC === true) {
      post('/api/assign/get_kpc_recommendations_limited/', { wprog_number: this.props.selectedWProg.value })
        .then((res) => {
          this.setState({
            recommended_kpc: res.data.ranked_kpc,
            kpcButtonText: 'Show all KPC',
            showAllKPC: false,
            loadingLayerIsActive: false,
          });
        });
      this.setState({ loadingLayerIsActive: true });
    } else {
      post('/api/assign/get_kpc_recommendations_full/', { wprog_number: this.props.selectedWProg.value })
        .then((res) => {
          this.setState({
            recommended_kpc: res.data.ranked_kpc,
            showAllKPC: true,
            kpcButtonText: 'Show most relevant KPC',
            loadingLayerIsActive: false,
          });
        });
      this.setState({ loadingLayerIsActive: true });
    }
  };

  toggleAllKPCI = () => {
    if (this.state.showAllKPCI === true) {
      post('/api/assign/get_kpci_recommendations_limited/', { wprog_number: this.props.selectedWProg.value })
        .then((res) => {
          this.setState({
            recommended_kpci: res.data.ranked_kpci,
            showAllKPCI: false,
            kpciButtonText: 'Show all KPCI',
            loadingLayerIsActive: false,
          });
        });
      this.setState({ loadingLayerIsActive: true });
    } else {
      post('/api/assign/get_kpci_recommendations_full/', { wprog_number: this.props.selectedWProg.value })
        .then((res) => {
          this.setState({
            recommended_kpci: res.data.ranked_kpci,
            showAllKPCI: true,
            kpciButtonText: 'Show most relevant KPCI',
            loadingLayerIsActive: false,
          });
        });
      this.setState({ loadingLayerIsActive: true });
    }
  };

  render() {
    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.documentTab === 'policy' })}
                  onClick={() => { this.document_toggle('policy'); }}
                >
                  <strong>Policy</strong>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.documentTab === 'kpc' })}
                  onClick={() => { this.document_toggle('kpc'); }}
                >
                  <strong>KPC</strong>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.documentTab === 'kpci' })}
                  onClick={() => { this.document_toggle('kpci'); }}
                >
                  <strong>KPCI</strong>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.documentTab === 'issue' })}
                  onClick={() => { this.document_toggle('issue'); }}
                >
                  <strong>Issue</strong>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.documentTab}>
              <TabPane tabId="policy">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.policyTab === '1' })}
                          onClick={() => { this.selection_toggle('policy', '1'); }}
                        >
                          <strong>Recommendations</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.policyTab === '2' })}
                          onClick={() => { this.selection_toggle('policy', '2'); }}
                        >
                          <strong>Assignments</strong>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.policyTab}>
                      <TabPane tabId="1">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11" />
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.RecommendedPolicyTable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <ReactTable
                            ref={(r) => { this.RecommendedPolicyTable = r; }}
                            data={this.state.recommended_policies}
                            columns={[
                              { Header: 'ID', accessor: 'Policy_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'Title', maxWidth: 450 },
                              { Header: 'Summary', accessor: 'Summary' },
                              { Header: 'Applicability', accessor: 'Applicability', maxWidth: 110 },
                              { Header: 'Relevance', id: 'relevance',
                                Cell: (row) => (
                                  <RelevanceExplanation relevance_score={row.original['Relevance']} data={row} />
                                ),
                                maxWidth: 90 },
                              { id: 'button', accessor: 'Policy_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.assign_policy_and_update(value); }}>Assign</button>),
                                maxWidth: 70 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-pol-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="ID" field={row.original.Policy_ID} split="1" />
                                          <TwoColLine name="Title" field={row.original.Title} split="1" />
                                          <TwoColLine name="Summary" field={row.original.Summary} split="1" />
                                          <TwoColLine name="Short History" field={row.original.Short_History} split="1" />
                                          <TwoColLine name="History" field={row.original.History} split="1" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Applicability" field={row.original.Applicability} split="4" />
                                          <TwoColLine name="Business Division" field={row.original.Business_Devision} split="4" />
                                          <TwoColLine name="Category" field={row.original.Category} split="4" />
                                          <TwoColLine name="State" field={row.original.State} split="4" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="3">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Creation Date" field={row.original.Creation_Date} split="6" />
                                          <TwoColLine name="Modification Date" field={row.original.Modification_Date} split="6" />
                                          <TwoColLine name="Publication Date" field={row.original.Publication_Date} split="6" />
                                          <TwoColLine name="Review Date" field={row.original.Review_Date} split="6" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="5">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Owner Title" field={row.original.Owner_Title} split="5" />
                                          <TwoColLine name="Owner Business Group" field={row.original.Owner_BusinessGroup} split="5" />
                                          <TwoColLine name="Approval Title" field={row.original.Senior_Approval_Title} split="5" />
                                          <TwoColLine name="Approval Business Group" field={row.original.Senior_Approval_BusinessGroup} split="5" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                      <TabPane tabId="2">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11" />
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.AssignedPolicyTable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col />
                          </Row>
                          <ReactTable
                            ref={(r) => { this.AssignedPolicyTable = r; }}
                            data={this.state.assigned_policies}
                            columns={[
                              { Header: 'ID', accessor: 'Policy_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'Title', maxWidth: 450 },
                              { Header: 'Summary', accessor: 'Summary' },
                              { id: 'button', accessor: 'Policy_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.unassign_policy_and_update(value); }}>Unassign</button>),
                                maxWidth: 80 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-pol-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="ID" field={row.original.Policy_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.Title} split="2" />
                                          <TwoColLine name="Summary" field={row.original.Summary} split="2" />
                                          <TwoColLine name="Short History" field={row.original.Short_History} split="2" />
                                          <TwoColLine name="History" field={row.original.History} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="5">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Applicability" field={row.original.Applicability} split="4" />
                                          <TwoColLine name="Business Division" field={row.original.Business_Devision} split="4" />
                                          <TwoColLine name="Category" field={row.original.Category} split="4" />
                                          <TwoColLine name="State" field={row.original.State} split="4" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="3">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Creation Date" field={row.original.Creation_Date} split="7" />
                                          <TwoColLine name="Modification Date" field={row.original.Modification_Date} split="7" />
                                          <TwoColLine name="Publication Date" field={row.original.Publication_Date} split="7" />
                                          <TwoColLine name="Review Date" field={row.original.Review_Date} split="7" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="4">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Owner Title" field={row.original.Owner_Title} split="7" />
                                          <TwoColLine name="Owner Business Group" field={row.original.Owner_BusinessGroup} split="7" />
                                          <TwoColLine name="Approval Title" field={row.original.Senior_Approval_Title} split="7" />
                                          <TwoColLine name="Approval Business Group" field={row.original.Senior_Approval_BusinessGroup} split="7" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="kpc">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.kpcTab === '1' })}
                          onClick={() => { this.selection_toggle('kpc', '1'); }}
                        >
                          <strong>Recommendations</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.kpcTab === '2' })}
                          onClick={() => { this.selection_toggle('kpc', '2'); }}
                        >
                          <strong>Assignments</strong>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.kpcTab}>
                      <TabPane tabId="1">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11">
                              <Button color="primary" onClick={this.toggleAllKPC}> {this.state.kpcButtonText} </Button>
                            </Col>
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.RecommendedKPCTable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <ReactTable
                            ref={(r) => { this.RecommendedKPCTable = r; }}
                            data={this.state.recommended_kpc}
                            columns={[
                              { Header: 'ID', accessor: 'KPC_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'KPC_Title' },
                              { Header: 'Summary', accessor: 'KPC_Description' },
                              { Header: 'Relevance', accessor: 'Relevance', width: 80 },
                              { id: 'button', accessor: 'KPC_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.assign_kpc_and_update(value); }}>Assign</button>),
                                maxWidth: 70 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-pol-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="ID" field={row.original.KPC_ID} split="1" />
                                          <TwoColLine name="Title" field={row.original.KPC_Title} split="1" />
                                          <TwoColLine name="Description" field={row.original.KPC_Description} split="1" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                      <TabPane tabId="2">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11" />
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.AssignedKPCTable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <ReactTable
                            ref={(r) => { this.AssignedKPCTable = r; }}
                            data={this.state.assigned_kpc}
                            columns={[
                              { Header: 'ID', accessor: 'KPC_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'KPC_Title' },
                              { Header: 'Summary', accessor: 'KPC_Description' },
                              { id: 'button', accessor: 'KPC_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.unassign_kpc_and_update(value); }}>Unassign</button>),
                                maxWidth: 80 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-pol-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="ID" field={row.original.KPC_ID} split="1" />
                                          <TwoColLine name="Title" field={row.original.KPC_Title} split="1" />
                                          <TwoColLine name="Description" field={row.original.KPC_Description} split="1" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="kpci">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.kpciTab === '1' })}
                          onClick={() => { this.selection_toggle('kpci', '1'); }}
                        >
                          <strong>Recommendations</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.kpciTab === '2' })}
                          onClick={() => { this.selection_toggle('kpci', '2'); }}
                        >
                          <strong>Assignments</strong>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.kpciTab}>
                      <TabPane tabId="1">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11">
                              <Button color="primary" onClick={this.toggleAllKPCI}> {this.state.kpciButtonText} </Button>
                            </Col>
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.RecommendedKPCITable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <ReactTable
                            ref={(r) => { this.RecommendedKPCITable = r; }}
                            data={this.state.recommended_kpci}
                            columns={[
                              { Header: 'KPC ID', accessor: 'KPC_ID', maxWidth: 100 },
                              { Header: 'KPCI ID', accessor: 'KPCI_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'KPCI_Title' },
                              { Header: 'Summary', accessor: 'KPCI_Description' },
                              { Header: 'Relevance', accessor: 'Relevance', width: 80 },
                              { id: 'button', accessor: 'KPCI_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.assign_kpci_and_update(value); }}>Assign</button>),
                                maxWidth: 80 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-kpci-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="KPCI-ID" field={row.original.KPCI_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.KPCI_Title} split="2" />
                                          <TwoColLine name="Description" field={row.original.KPCI_Description} split="2" />
                                          <TwoColLine name="Local Functional Information" field={row.original.KPCI_Local_Functional_Information} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                      <TabPane tabId="2">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11" />
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.AssignedKPCITable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <ReactTable
                            ref={(r) => { this.AssignedKPCITable = r; }}
                            data={this.state.assigned_kpci}
                            columns={[
                              { Header: 'KPC ID', accessor: 'KPC_ID', maxWidth: 100 },
                              { Header: 'ID', accessor: 'KPCI_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'KPCI_Title' },
                              { Header: 'Summary', accessor: 'KPCI_Description' },
                              { id: 'button', accessor: 'KPCI_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.unassign_kpci_and_update(value); }}>Unassign</button>),
                                maxWidth: 80 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-kpci-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="KPCI-ID" field={row.original.KPCI_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.KPCI_Title} split="2" />
                                          <TwoColLine name="Is Active" field={row.original.Active_KPCI} split="2" />
                                          <TwoColLine name="Description" field={row.original.KPCI_Description} split="2" />
                                          <TwoColLine name="Local Functional Information" field={row.original.KPCI_Local_Functional_Information} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="GCRS Information" field="" split="8" />
                                          <Row>
                                            <Col>
                                              <TwoColLine name="Location Name" field={row.original.KPCI_GCRS_Location_Name} split="5" />
                                              <TwoColLine name="Location ID" field={row.original.KPCI_GCRS_Location_ID} split="5" />
                                              <TwoColLine name="Location Sub Region" field={row.original.KPCI_GCRS_Location_Sub_Region} split="5" />
                                              <TwoColLine name="Region Name" field={row.original.KPCI_GCRS_Location_Region} split="5" />
                                              <TwoColLine name="Location Legal Entity" field={row.original.KPCI_GCRS_Location_Legal_Entity} split="5" />
                                              <TwoColLine name="Country Name" field={row.original.KPCI_GCRS_Location_Country} split="5" />
                                            </Col>
                                            <Col>
                                              <TwoColLine name="Function Name" field={row.original.KPCI_GCRS_Function_Name} split="5" />
                                              <TwoColLine name="Function ID" field={row.original.KPCI_GCRS_Function_ID} split="5" />
                                              <TwoColLine name="Unit Name" field={row.original.KPCI_GCRS_Function_Business_Unit} split="5" />
                                              <TwoColLine name="Segment Name" field={row.original.KPCI_GCRS_Function_Business_Segment} split="5" />
                                              <TwoColLine name="Sector Name" field={row.original.KPCI_GCRS_Function_Business_Sector} split="5" />
                                              <TwoColLine name="Function Business Function" field={row.original.KPCI_GCRS_Function_Business_Function} split="5" />
                                              <TwoColLine name="Division Name" field={row.original.KPCI_GCRS_Function_Business_Devision} split="5" />
                                              <TwoColLine name="Area Name" field={row.original.KPCI_GCRS_Function_Business_Area} split="5" />
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="SOX Details" field="" split="5" />
                                          <TwoColLine name="Control Instance SOX Relevant" field={row.original.KPCI_SOX_Relevant} split="7" />
                                          <TwoColLine name="SOX relevant Control for Testing" field={row.original.KPCI_SOX_relevant_Control_for_Testing} split="7" />
                                          <TwoColLine name="Assertion Valuation Allocation" field={row.original.KPCI_SOX_Assertion_Valuation_Allocation} split="7" />
                                          <TwoColLine name="Assertion Rights and Obligations" field={row.original.KPCI_SOX_Assertion_Rights_and_Obligations} split="7" />
                                          <TwoColLine name="Assertion Presentation and Disclosure" field={row.original.KPCI_SOX_Assertion_Presentation_and_Disclosure} split="7" />
                                          <TwoColLine name="Assertion Existence Occurrence" field={row.original.KPCI_SOX_Assertion_Existence_Occurrence} split="7" />
                                          <TwoColLine name="Assertion Completeness" field={row.original.KPCI_SOX_Assertion_Completeness} split="7" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Control Instance Owner Name" field={row.original.KPCI_Owner_Name} split="6" />
                                          <TwoColLine name="Control Instance Owner Role" field={row.original.KPCI_Owner_Role} split="6" />
                                          <TwoColLine name="Control Instance Nature" field={row.original.KPCI_Nature} split="6" />
                                          <TwoColLine name="EY Reliant" field={row.original.KPCI_EY_Reliant} split="6" />
                                          <TwoColLine name="PM Required" field={row.original.KPCI_PM_Required} split="6" />
                                          <TwoColLine name="PM Available From" field={row.original.KPCI_PM_Available_From} split="6" />
                                          <TwoColLine name="Manual/Automation Level" field={row.original.KPCI_Manual_Automation_Level} split="6" />
                                          <TwoColLine name="Execution Frequency" field={row.original.KPCI_Execution_Frequency} split="6" />
                                          <TwoColLine name="Include in Test Plan Automation" field={row.original.KPCI_Include_in_Test_Plan_Automation} split="6" />
                                          <TwoColLine name="Evidence Description" field={row.original.KPCI_Evidence_Description} split="6" />
                                          <TwoColLine name="Evidence Available From" field={row.original.KPCI_Evidence_Available_From} split="6" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="issue">
                <Card>
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.issueTab === '1' })}
                          onClick={() => { this.selection_toggle('issue', '1'); }}
                        >
                          <strong>Recommendations</strong>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.issueTab === '2' })}
                          onClick={() => { this.selection_toggle('issue', '2'); }}
                        >
                          <strong>Assignments</strong>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.issueTab}>
                      <TabPane tabId="1">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11" />
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.RecommendedIssueTable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col />
                          </Row>
                          <ReactTable
                            ref={(r) => { this.RecommendedIssueTable = r; }}
                            data={this.state.recommended_issues}
                            columns={[
                              { Header: 'ID', accessor: 'Issue_ID', maxWidth: 130 },
                              { Header: 'Title', accessor: 'Issue_Title' },
                              { Header: 'Highest Overall Rating', accessor: 'Issue_Highest_Rating', maxWidth: 80, className: "center" },
                              { Header: 'Relevance', accessor: 'Relevance', maxWidth: 100 },
                              { id: 'button', accessor: 'Issue_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.assign_issue_and_update(value); }}>Assign</button>),
                                maxWidth: 70 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-issue-subcomponent">
                                  <Row>
                                    <Col lg="12">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="ID" field={row.original.Issue_ID} split="1" />
                                          <TwoColLine name="Title" field={row.original.Issue_Title} split="1" />
                                          <TwoColLine name="Description" field={row.original.Issue_Desription} split="1" />
                                          <TwoColLine name="Highest Overall Rating" field={row.original.Issue_Highest_Rating} split="1" />
                                          <TwoColLine name="Status" field={row.original.Issue_Status_Carat} split="1" />
                                          <TwoColLine name="Issue Owner" field={row.original.Issue_Owner_Name} split="1" />
                                          <TwoColLine name="Creation On" field={row.original.Issue_Date_Created} split="1" />
                                          <TwoColLine name="Mitigation Date" field={row.original.Issue_Current_Issue_Mitigation_Date} split="1" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                      <TabPane tabId="2">
                        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
                          <Row id="Excel_icon_row">
                            <Col lg="11" />
                            <Col>
                              <Button block color="link" onClick={() => {
                                var data = this.AssignedIssueTable.getResolvedState().sortedData.map(x => x._original);
                                downloadCSV({
                                  data: data,
                                  separator: ';',
                                  filename: 'filtered_export.csv',
                                });
                              }}>
                                <img src={Excel_icon} height="20" width="20" id="download_icon" alt=""/>
                              </Button>
                            </Col>
                          </Row>
                          <ReactTable
                            ref={(r) => { this.AssignedIssueTable = r; }}
                            data={this.state.assigned_issues}
                            columns={[
                              { Header: 'ID', accessor: 'Issue_ID', maxWidth: 160 },
                              { Header: 'Title', accessor: 'Issue_Title', maxWidth: 500 },
                              { Header: 'Highest Overall Rating', accessor: 'Issue_Highest_Rating', maxWidth: 80, className: "center" },
                              { id: 'button', accessor: 'Issue_ID',
                                Cell: ({ value }) => (<button onClick={() => { this.unassign_issue_and_update(value); }}>Unassign</button>),
                                maxWidth: 80 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1 : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-pol-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="ID" field={row.original.Issue_ID} split="1" />
                                          <TwoColLine name="Title" field={row.original.Issue_Title} split="1" />
                                          <TwoColLine name="Highest Overall Rating" field={row.original.Issue_Highest_Rating} split="1" />
                                          <TwoColLine name="Status" field={row.original.Issue_Status_Carat} split="1" />
                                          <TwoColLine name="Issue Owner" field={row.original.Issue_Owner_Name} split="1" />
                                          <TwoColLine name="Creation On" field={row.original.Issue_Date_Created} split="1" />
                                          <TwoColLine name="Mitigation Date" field={row.original.Issue_Current_Issue_Mitigation_Date} split="1" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </LoadingOverlay>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AssignmentProcess;
