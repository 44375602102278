import React, { Component } from 'react';
import { Col, FormText } from 'reactstrap';
import dayjs from 'dayjs';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: dayjs().subtract(12, 'month'),
      endDate: dayjs(),
    };
  }

  handleChange = ({ startDate, endDate }) => {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    const fromYear = startDate.year();
    const fromMonth = startDate.month() + 1;
    const fromDay = startDate.date();

    const toYear = endDate.year();
    const toMonth = endDate.month() + 1;
    const toDay = endDate.date();

    this.props.setFromDate(fromYear, fromMonth, fromDay);
    this.props.setToDate(toYear, toMonth, toDay);

    this.setState({ startDate, endDate });
  };

  componentDidMount() {
    const { startDate, endDate } = this.state;
    const fromYear = startDate.year();
    const fromMonth = startDate.month() + 1;
    const fromDay = startDate.date();

    const toYear = endDate.year();
    const toMonth = endDate.month() + 1;
    const toDay = endDate.date();

    this.props.setFromDate(fromYear, fromMonth, fromDay);
    this.props.setToDate(toYear, toMonth, toDay);
  }

  handleChangeStart = (date) => {
    this.handleChange({ startDate: dayjs(date) });
  };

  handleChangeEnd = (date) => {
    this.handleChange({ endDate: dayjs(date) });
  };

  render() {
    if (this.props.versioning) {
      return (
        <div className="row">
          <Col>
            <FormText className="help-block">
              Historical comparison date: From
            </FormText>
            <DatePicker
              selected={this.state.startDate.toDate()}
              selectsStart
              startDate={this.state.startDate.toDate()}
              endDate={this.state.endDate.toDate()}
              onChange={(date) => this.handleChangeStart(date)}
              dateFormat="MMMM dd, yyyy"
            />
          </Col>
          <Col>
            <FormText className="help-block">To</FormText>
            <DatePicker
              disabled={true}
              selected={this.state.endDate.toDate()}
              selectsEnd
              startDate={this.state.startDate.toDate()}
              endDate={this.state.endDate.toDate()}
              onChange={(date) => this.handleChangeEnd(date)}
              minDate={this.state.startDate.toDate()}
              dateFormat="MMMM dd, yyyy"
            />
          </Col>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default DateTimePicker;
