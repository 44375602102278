import React, { Component } from 'react';

import "./style.css"
import { get } from "../../services/http";
import WorkProgramSelector from '../WorkProgramSelector/WorkProgramSelector.js';
import AuditStrategy from '../AuditStrategy/AuditStrategy.js';
import DashboardRelated from '../DashboardRelated/DashboardRelated.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wprogOptionList: [],
      selectedWProg: null,
    };
  }
  componentDidMount(){
      get('/api/assign/get_workprograms/')
      .then( (res) => {
        const wprogOptionList = res.data.wprog_list[0];
        this.setState({wprogOptionList: wprogOptionList})
      });
  }

  changeSelect = (selectedOption) => {
    console.log(selectedOption);
    console.log(this.state);
    if (this.state.wprogOptionList.indexOf(selectedOption) > -1){
      this.setState({selectedWProg: selectedOption});
    }
  }

  render(){
    const title = 'Document Dashboard';
    const subtitle = 'Audit Work Program';
    if(this.state.selectedWProg){
      return(
        <div className="animated fadeIn">
          <WorkProgramSelector
            title={title}
            subtitle={subtitle}
            selectedWProg={this.state.selectedWProg}
            wprogOptionList={this.state.wprogOptionList}
            changeSelect={this.changeSelect}
          />
          <AuditStrategy
            selectedWProg={this.state.selectedWProg}
          />
          <DashboardRelated
            selectedWProg={this.state.selectedWProg}
          />
        </div>
      )
    } else {
      return(
        <div className="animated fadeIn">
          <WorkProgramSelector
            title={title}
            subtitle={subtitle}
            selectedWProg={this.state.selectedWProg}
            wprogOptionList={this.state.wprogOptionList}
            changeSelect={this.changeSelect}
          />
        </div>
      )
    }
  }
}

export default Dashboard;
