import React, { Component } from 'react';
import './modal.css'
import Select from 'react-select'
import chroma from 'chroma-js'
import token from '../../../token';

import {
    Label,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Col,
    Row,

} from 'reactstrap'

import ListViewer from './ListViewer'

import { get, post } from "../../../services/http";


const color_default = '#333333'
const color_new = '#00875A'
const color_removed = '#FF5630'

const colourOptions = [

    { value: 'BA001', label: 'BA001', color: '#333333', isFixed: true, isDisabled: false },
    { value: 'BA002', label: 'BA002', color: '#333333', isFixed: true, isDisabled: false },
    { value: 'BA003', label: 'BA003', color: '#333333', isFixed: true, isDisabled: false },
    { value: 'BA004', label: 'BA004', color: '#00875A', isFixed: false, isDisabled: false },
    { value: 'BA005', label: 'BA005', color: '#00875A', isFixed: false, isDisabled: false },
    { value: 'BA006', label: 'BA006', color: '#00875A', isFixed: false, isDisabled: false },
    { value: 'BA007', label: 'BA007', color: '#00875A', isFixed: false, isDisabled: true },
];




const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFixed, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
};




const colourStylesUnassigned = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFixed, isFocused, isSelected }) => {
        const color = chroma(color_removed);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? color_removed
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? color_removed : color.alpha(0.3).css()),
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(color_removed);
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: color_removed,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: color_removed,
        ':hover': {
            backgroundColor: color_removed,
            color: 'white',
        },
    }),
};



function isInList(num, list) {
    // List is an Array()
    var result = false;
    for (var i in list) {
        if (num == list[i]) { result = true }
    }
    return result
}



class AssignExpandButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            comment: '',
            assignment_comments: [],
            assignedDocuments: { 'assigned': [], 'unassigned': [], 'default': [] },
            wizardSelected: 0,
            modifyActions: false,
        };

        this.toggleModal = this.toggleModal.bind(this)
        this.assignDocs = this.assignDocs.bind(this)
        this.handleComment = this.handleComment.bind(this)
        this.updateAssignedDocuments = this.updateAssignedDocuments.bind(this)
        this.postModifiedAssignments = this.postModifiedAssignments.bind(this)
        this.onWizardBtnClick = this.onWizardBtnClick.bind(this)
        this.nextButton = this.nextButton.bind(this)
        this.updateComments = this.updateComments.bind(this)
        this.fetchAssignmentInformation = this.fetchAssignmentInformation.bind(this)
    }

    componentDidMount() {
        get('/api/secure/check_token')
            .then(res => {
                if (res.data.role !== "READ") {
                    this.setState({ modifyActions: true });
                }
            })
    }

    updateComments(key, comment) {

        //console.log('placeholder')
        //console.log(key)
        //console.log(comment)
        var new_assignment_comments = this.state.assignment_comments


        for (var i = 0; i < new_assignment_comments.length; i++) {
            if (new_assignment_comments[i].key == key) {
                //console.log('test')
                new_assignment_comments[i].comment = comment
            }
        }

        //console.log(new_assignment_comments)

        this.setState({ assignment_comments: new_assignment_comments })

    }

    postModifiedAssignments() {

        //TO DO
        //var post = this.state.assignedDocuments
        //post['comment'] = this.state.comment

        var temp_comments = this.state.assignment_comments
        var document_code = this.props.document_code

        var final_comments = []
        for (var i = 0; i < temp_comments.length; i++) {
            if (temp_comments[i].status != 'default') {

                final_comments.push(temp_comments[i])
            }
        }

        console.log(this.props.query_post_assignments)
        console.log(document_code)
        console.log(final_comments)

        post(this.props.query_post_assignments,
            {
                document_code: document_code,
                work_programs: final_comments,
                token: token.getToken(),
            })
            .then((res) => {
                console.log(res)

            })




        this.setState({
            modal: !this.state.modal,
            wizardSelected: 0

        })

        this.props.trigger_update.triggerTableUpdate()
    }




    assignDocs() {


        //Check whether all comements are filled out
        var check_comment = true
        var temp_comments = this.state.assignment_comments
        for (var i = 0; i < temp_comments.length; i++) {
            if (temp_comments[i].status != 'default') {

                if (temp_comments[i].comment.length == 0) {
                    check_comment = false
                }
            }
        }




        if (!check_comment) {

            alert('Please fill out the comment fields')
        }
        else if (this.state.assignedDocuments.unassigned.length > 0) {
            var confirm = window.confirm(
                "WARNING: You just unassigned a document from one or more work programs. Press OK if this was intended, else press Cancel"
            )


            if (confirm == true) {

                this.postModifiedAssignments()


            }




        }

        else if (this.state.assignedDocuments.unassigned.length == 0 && this.state.assignedDocuments.assigned.length == 0) {

            alert('No assignments or unassignments were requested by the user. Please use the cancel button instead')
        }


        else {
            this.postModifiedAssignments()

        }

        //this.setState({
        //    modal: !this.state.modal,
        //})

    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal,
            comment: "",
        });
    }

    handleComment(event) {
        this.setState(
            { comment: event.target.value }
        )
    }

    updateAssignedDocuments(assigned, unassigned, default_selection) {
        var default_change = []

        if (default_selection.length > 0) {

            for (var i = 0; i < default_selection.length; i++) {
                if (!isInList(default_selection[i].value, unassigned.map(x => x.value))) {
                    default_change.push(default_selection[i])

                }


            }
        }
        else {
            var default_change = default_selection
        }
        var tempAssigned = { 'assigned': assigned, 'unassigned': unassigned, 'default': default_change }

        this.setState({ assignedDocuments: tempAssigned })

    }



    //fetch assignments when wizardSelected becomes 1

    fetchAssignmentInformation() {
        var temp_assignments = this.state.assignedDocuments

        var all_assignments_complete = temp_assignments.unassigned.map(function (x) {
            var x_temp = x
            x_temp['status'] = 'unassigned'
            return (x_temp)

        }
        )
            .concat(temp_assignments.assigned.map(function (x) {
                var x_temp = x
                x_temp['status'] = 'assigned'
                return (x_temp)

            }))
            .concat(temp_assignments.default.map(function (x) {
                var x_temp = x
                x_temp['status'] = 'default'
                return (x_temp)

            }))



        var all_assignments = temp_assignments.unassigned.map(x => x.value)
            .concat(temp_assignments.assigned.map(x => x.value))
            .concat(temp_assignments.default.map(x => x.value))

        var document_code = this.props.document_code
        console.log(all_assignments)
        if (all_assignments.length > 0) {

               post(this.props.query_fetch_selected_assignments,
                    {
                        document_code: document_code,
                        work_programs: all_assignments
                    })
                    .then((res) => {
                        console.log(res.data)
                        //Double data.data should be eliminated
                        var comment_list = res.data.data[1]
                        var new_assignment_comments = []

                        console.log(comment_list)

                        for (var i = 0; i < all_assignments_complete.length; i++) {
                            console.log(i)
                            var temp_comment = ''
                            var temp_author = ''
                            var temp_date = ''

                            for (var j = 0; j < comment_list.length; j++) {

                                if (comment_list[j].WProg_Number == all_assignments_complete[i].value) {

                                    temp_comment = comment_list[j].Comment
                                    temp_author = comment_list[j].Author
                                    temp_date = comment_list[j].Date
                                }

                            }


                            var entry = {
                                'key': i,
                                'work_program': all_assignments_complete[i].value,
                                'comment': '',
                                'previous_comment': temp_comment,
                                'date': temp_date,
                                'author': temp_author,
                                'status': all_assignments_complete[i].status,
                                'work_program_title': all_assignments_complete[i].label
                            }


                            new_assignment_comments.push(entry)

                        }


                        this.setState({ assignment_comments: new_assignment_comments })

                    });




            //{ 'key': 1, 'work_program': 'BA001', 'comment': '', 'date': '3 days ago', 'author': 'XXX', 'status': 'unassigned' },

        }



    }



    onWizardBtnClick(wizardSelected) {
        this.setState({
            wizardSelected: wizardSelected
        });

        if (wizardSelected == 1) {
            this.fetchAssignmentInformation()
        }

    }

    nextButton() {

        this.setState({ wizardSelected: 1 })
        this.fetchAssignmentInformation()
    }



    render() {

        if (this.props.work_program == "YES") {
            var color = "secondary"
        }
        else {
            var color = "light"
        }


        //Determine what is shown, quick fix to prevent unmounting of objects
        if (this.state.wizardSelected == 0) {
            var display0 = 'display1';
            var display1 = 'display0';
        }
        else if (this.state.wizardSelected == 1) {
            var display0 = 'display0';
            var display1 = 'display1';
        }

        return (

            <div>
                <Button size='sm'
                    block
                    color={color} //"ghost-dark"
                    onClick={this.toggleModal} >

                    {this.props.work_program}

                </Button>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} backdrop={false}
                    className='modal-lg'>
                    <ModalHeader toggle={this.toggleLarge}>Assignment Menu - Document {this.props.document_code}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md='12'>
                                <ButtonGroup className='btn-block' size='sm'>
                                    <Button color="outline-dark" active={this.state.wizardSelected === 0} onClick={() => this.onWizardBtnClick(0)}>Assignment</Button>
                                    <Button color="outline-dark" active={this.state.wizardSelected === 1} onClick={() => this.onWizardBtnClick(1)}>Comment</Button>
                                </ButtonGroup>
                            </Col>



                        </Row>
                        <br />
                        <Row>
                            <Col md='12'>
                                <div className={display0} >

                                    <WorkprogramSelect
                                        wprogOptionList={this.props.wprogOptionList}
                                        document_code={this.props.document_code}
                                        parentUpdate={this.updateAssignedDocuments}
                                        query_fetch_linked_workprograms={this.props.query_fetch_linked_workprograms}
                                    />

                                </div>

                                {/* <hr />

                                    <Label> Reason for modification </Label>
                                    <Row>
                                        <Col>

                                            <Input
                                                required type="textarea"
                                                rows="3"
                                                value={this.state.comment}
                                                onChange={this.handleComment}
                                                placeholder="Please provide an argumentation for the changed assignments" />
                                        </Col>
                                    </Row>*/}

                                {/* Shown in tab 2*/}
                                <div className={display1}>
                                    <ListViewer list={this.state.assignment_comments} updateComments={this.updateComments} />
                                </div>
                            </Col>

                        </Row>         </ModalBody>
                    <ModalFooter>
                        <Col>
                            <ButtonToolbar className="float-left">
                                <ButtonGroup className="float-left">
                                    <Button size='sm' className="float-left" color="secondary" onClick={this.toggleModal}>Cancel</Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Col>
                        <Col>
                            {this.state.modifyActions ?

                                <ButtonToolbar className="float-right">
                                    <ButtonGroup className="mr-3">
                                        {this.state.wizardSelected == 0 &&
                                            <Button size='sm' className='float-right' color="info" onClick={this.nextButton}>Next</Button>}
                                        {this.state.wizardSelected == 1 &&
                                            <Button size='sm' className='float-right' color="info" onClick={this.assignDocs}>Modify</Button>}
                                    </ButtonGroup>
                                </ButtonToolbar>
                                : <div></div>}
                        </Col>
                    </ModalFooter>
                </Modal>


            </div>
        )





    }


}



export default AssignExpandButton


class WorkprogramSelect extends React.Component {


    constructor(props) {
        super(props);

        this.defaultSelected = [colourOptions[0], colourOptions[1], colourOptions[2]] //Default workprograms
        this.allOptions = colourOptions //All workprograms

        //Binding this to internal functions
        this.selectHandler = this.selectHandler.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeUnassigned = this.onChangeUnassigned.bind(this)



        this.state = {
            selectedItems: [], // selected options, needs to be managed
            selectedOptions: [],
            unassignedItems: [],                        //unassigned items
            defaultSelected: [],
            value: null,
            extra_assigned: []
        };


    }
    componentDidMount() {
        post(this.props.query_fetch_linked_workprograms, { document_code: this.props.document_code })
        .then((res) => {
            console.log("GET LINKED WORK PROGRAMS")
            console.log(res.data)
            var tempDefaultSelected = res.data.linked_workprograms[0]
            var tempOptions = this.props.wprogOptionList
            var DefaultAssignedIds = []


            //format selection
            for (var i = 0; i < tempDefaultSelected.length; i++) {
                tempDefaultSelected[i]['color'] = color_default
                tempDefaultSelected[i]['isFixed'] = true
                tempDefaultSelected[i]['isDisabled'] = false
                DefaultAssignedIds.push(tempDefaultSelected[i].label)

            }
            //format all
            //format selection
            for (var i = 0; i < tempOptions.length; i++) {
                //TODO: Value and label definition should be made consistent
                if (isInList(tempOptions[i].label, DefaultAssignedIds)) {

                    tempOptions[i]['color'] = color_default
                    tempOptions[i]['isFixed'] = true
                    tempOptions[i]['isDisabled'] = false
                }
                else {

                    tempOptions[i]['color'] = color_new
                    tempOptions[i]['isFixed'] = false
                    tempOptions[i]['isDisabled'] = false
                }

                //tempDefaultSelected[i]['color'] = color_default
                //tempDefaultSelected[i]['isFixed'] = false
                //tempDefaultSelected[i]['isDisabled']= false
            }


            this.setState({
                defaultSelected: tempDefaultSelected,
                selectedItems: tempDefaultSelected,
                selectedOptions: tempOptions
            })

            //Parent should be aware of initial default selection



            return (tempDefaultSelected)
        }).then(x =>
            //Initialize the selected work programs in the parent class
            this.props.parentUpdate([], [], x)
        )
    }

    //Helper function for ordering a list of values.
    orderOptions = values => {
        if (values) {
            return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
        }
        else {
            return (values)
        }
    };


    //Stores the selected items in a state, TO BE REMOVED
    selectHandler(e) {
        this.setState({ selectedItems: e })
    }

    //Handles change of the top selection menu.
    onChange(value, { action, removedValue }) {
        var unassignedItems = this.state.unassignedItems

        switch (action) {
            case 'remove-value':

                var inDefault = isInList(removedValue.label, this.state.defaultSelected.map(x => x.label)) //Check if a default value is removed
                if (inDefault) { //removedValue.isFixed
                    unassignedItems.push(removedValue)

                }

                if (value) {
                    for (var i = value.length - 1; i >= 0; i--) {
                        if (value[i] === removedValue) {
                            value.splice(i, 1);
                        }
                    }
                }

                break;
            case 'pop-value':
                //TODO: Introduce same logic as with remove-value for pop
                console.log('pop')
                break;

            case 'select-option':

                var unassignedTemp = []
                for (var i = 0; i < unassignedItems.length; i++) {
                    if (!isInList(unassignedItems[i], value)) {
                        unassignedTemp.push(unassignedItems[i])
                    }
                }
                unassignedItems = unassignedTemp

                break;

            case 'clear':
                value = this.state.defaultSelected//colourOptions.filter(v => v.isFixed);
                unassignedItems = []
                break;
        }



        value = this.orderOptions(value);
        unassignedItems = this.orderOptions(unassignedItems)



        var extra_assigned = []
        if (value) {
            for (var i = 0; i < value.length; i++) {
                var inDefaultSelect = isInList(value[i], this.state.defaultSelected)
                if (!inDefaultSelect) {
                    extra_assigned.push(value[i])
                }

            }
        }
        else {
            extra_assigned = []
        }

        //console.log(extra_assigned)

        //console.log('test')
        //console.log(value, unassignedItems, extra_assigned)

        this.setState({
            selectedItems: value,
            unassignedItems: unassignedItems,
            extra_assigned: extra_assigned

        });
        this.props.parentUpdate(extra_assigned, unassignedItems, this.state.defaultSelected)

    }


    //Handles changes in the bottom selection menu
    onChangeUnassigned(value, { action, removedValue }) {
        var unassignedItems = this.state.unassignedItems
        var reassignedItems = this.state.selectedItems
        console.log("TEST UNASSIGNED")

        switch (action) {
            case 'remove-value':

                for (var i = unassignedItems.length - 1; i >= 0; i--) {
                    if (unassignedItems[i].label === removedValue.label) {

                        unassignedItems.splice(i, 1);
                    }
                }

                if (reassignedItems) {
                    reassignedItems.push(removedValue)
                }
                else {
                    reassignedItems = [removedValue]
                }

                break;
            case 'pop-value':

                break;

            case 'select-option':

                var reassignedTemp = []
                for (var i = 0; i < reassignedItems.length; i++) {
                    if (!isInList(reassignedItems[i], value)) {
                        reassignedTemp.push(reassignedItems[i])
                    }
                }
                reassignedItems = reassignedTemp

                unassignedItems = value
                break;


            case 'clear':


                //for (var i = 0; i < unassignedItems.length; i++) {
                //    reassignedItems.push(unassignedItems)
                //}
                if (reassignedItems) {
                    reassignedItems = reassignedItems.concat(unassignedItems)
                }
                else {
                    reassignedItems = unassignedItems
                }
                unassignedItems = [];
                break;
        }



        //value = this.orderOptions(value);
        //unassign = this.orderOptions(unassign)




        reassignedItems = this.orderOptions(reassignedItems)
        unassignedItems = this.orderOptions(unassignedItems)

        this.setState({
            //selectedItems: value,
            //unassigned: unassign,
            selectedItems: reassignedItems,
            unassignedItems: unassignedItems
        });


        var extra_assigned = []


        if (reassignedItems) {
            for (var i = 0; i < reassignedItems.length; i++) {
                var inDefaultSelect = isInList(reassignedItems[i], this.state.defaultSelected)
                if (!inDefaultSelect) {
                    extra_assigned.push(reassignedItems[i])
                }

            }

        }
        //console.log(reassignedItems)
        this.props.parentUpdate(extra_assigned, unassignedItems, this.state.defaultSelected)
    }


    render() {


        return (
            <div>

                <Label>Assigned to work programs</Label>
                <Row>
                    <Col>

                        <Select
                            value={this.state.selectedItems}
                            closeMenuOnSelect={false}

                            isMulti
                            options={this.state.selectedOptions}
                            styles={colourStyles}
                            onChange={this.onChange}
                        /> </Col></Row><br />

                <Label>Will be unassigned to</Label>
                <Select
                    closeMenuOnSelect={false}
                    value={this.state.unassignedItems}
                    isMulti
                    options={this.state.defaultSelected}
                    styles={colourStylesUnassigned}
                    onChange={this.onChangeUnassigned}
                />




            </div>
        );
    }
}
