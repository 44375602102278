import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import history from "./history.js";

import './App.css';
// Styles
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { Full } from './containers';
// Pages
import { Page404, Page500, Register } from './views/Pages';
import App_Login from './views/App_Login' ;



class App extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <HashRouter history={history}>
        <Switch>
          <Route exact path="/login" name="Login Page" component={App_Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/*" name="Home" component={Full} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
