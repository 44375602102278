import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';

import Select from 'react-select' ;
import './style.css'

function WorkProgramSelector(props){
  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h2>{props.title}</h2> <br></br>
                </Col>
              </Row>
              <Row>
                <Col xs="2">
                <h5>{props.subtitle}</h5>
                </Col>
                <Col lg="10">
                  <Select
                    value={props.selectedWProg}
                    isClearable={true}
                    options={props.wprogOptionList}
                    onChange={props.changeSelect}
                    onSelectResetsInput={true}
                    placeholder="Select an Audit Work Program ..."
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default WorkProgramSelector;
