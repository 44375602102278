import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';

import navigation from '../../_nav';
import adminNavigation from '../../_nav_admin';
import routes from '../../routes';
import FullHeader from './FullHeader';
import { get } from '../../services/http';

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      username: '',
      usergroup: ''
    };
  }
  componentDidMount() {
    get("/api/secure/check_token/").then(res => {
      this.setValues('username', res.data.name);
      this.setValues('usergroup', res.data.role);
    });
  }

  setValues = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { username, usergroup, adminNav } = this.state;
    return (
      <div className="app">
        <AppHeader fixed>
          <FullHeader username={username} usergroup={usergroup} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            {adminNav
              ? <AppSidebarNav navConfig={adminNavigation} {...this.props} />
              : <AppSidebarNav navConfig={navigation} {...this.props} />}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Container fluid>
              <Switch>
                {routes.map((route, idx) =>
                  route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null
                )}
                {/* Default redirect for unmatched routes */}
                <Redirect from="/" to="/home" />
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default Full;
