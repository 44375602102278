import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoadingOverlay = ({ active, spinner, text, children }) => {
  if (!active) return <>{children}</>;

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 0, left: 0, right: 0, bottom: 0,
          background: 'rgba(255, 255, 255, 0.7)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        {spinner && (
          <TailSpin color="#00BFFF" height={80} width={80} />
        )}
        {text && <div style={{ marginTop: 10 }}>{text}</div>}
      </div>
      {children}
    </div>
  );
};

export default LoadingOverlay;
