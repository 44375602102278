import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import "./style.css";
import Excel_icon from '../Icons/download_icon/Excel_icon.png';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import { postAll } from "../../services/http";
import _ from 'lodash';
import { downloadCSV } from '../CSVExport/CSVExport.js';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';


function TwoColLine(props) {
  return (
    <div>
      <Row>
        <Col xs={props.split}>
          <strong>{props.name}:</strong>
        </Col>
        <Col>
          {props.field !== "01 Jan 1900" ? props.field : "-"}
        </Col>
      </Row>
    </div>
  );
}

const default_colors = [
  '#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC',
  '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499',
  '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262',
  '#5574A6', '#3B3EAC'
];

class DashboardRelated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      activeVisTab: "1",

      related_policies: [],
      related_kpc: [],
      related_kpci: [],
      related_issues: [],
      related_kpci_assessment: [],
      related_taxonomy: [],
      related_regulation: [],

      loadingLayerIsActive: false,

      policy_plot: {
        applicability_pie: { labels: [], datasets: [{ data: [] }] }
      },
      taxom_plot: {
        pie: { labels: [], datasets: [] }
      },
      kpc_plot: {
        pie: { labels: [], datasets: [] }
      },
      kpci_plot: {
        pie: { labels: [], datasets: [] }
      },
      assess_plot: {
        pie: { labels: [], datasets: [] }
      },
      issues_plot: {
        pie: { labels: [], datasets: [] }
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      let requests = [];
      requests.push({
        url: "/api/assign/get_policy_assignments/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_kpc_assignments/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_kpci_assignments/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_issue_assignments/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_kpci_assessment/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_taxonomy/",
        data: { wprog_number: nextProps.selectedWProg.value },
      }, {
        url: "/api/assign/get_regulation/",
        data: { wprog_number: nextProps.selectedWProg.value },
      });
      postAll(requests).then((result) => {
        this.setState({
          related_policies: result[0].data.assigned_policies[0],
          related_kpc: result[1].data.assigned_kpc[0],
          related_kpci: result[2].data.assigned_kpci[0],
          related_issues: result[3].data.assigned_issues[0],
          related_kpci_assessment: result[4].data.related_kpci_assessment[0],
          related_taxonomy: result[5].data.related_taxonomy[0],
          related_regulation: result[6].data.related_regulation[0],
          loadingLayerIsActive: false,
        });
      });
      this.setState({ loadingLayerIsActive: true });
    }
  }

  componentDidMount() {
    let requests = [];
    requests.push({
      url: "/api/assign/get_policy_assignments/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_kpc_assignments/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_kpci_assignments/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_issue_assignments/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_kpci_assessment/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_taxonomy/",
      data: { wprog_number: this.props.selectedWProg.value },
    }, {
      url: "/api/assign/get_regulation/",
      data: { wprog_number: this.props.selectedWProg.value },
    });
    postAll(requests).then((result) => {
      this.setState({
        related_policies: result[0].data.assigned_policies[0],
        related_kpc: result[1].data.assigned_kpc[0],
        related_kpci: result[2].data.assigned_kpci[0],
        related_issues: result[3].data.assigned_issues[0],
        related_kpci_assessment: result[4].data.related_kpci_assessment[0],
        related_taxonomy: result[5].data.related_taxonomy[0],
        related_regulation: result[6].data.related_regulation[0],
        loadingLayerIsActive: false,
      });
    });
    this.setState({ loadingLayerIsActive: true });
  }

  toggle_vis(tab) {
    if (this.state.activeVisTab !== tab) {
      this.setState({ activeVisTab: tab });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  open_regulation = (value) => {
    window.open(value, '_blank');
  };

  openPolicy(id) {
    window.open(process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/secure/get/policy/?id=' + id, '_blank');
  }

  get_summary_report = () => {
    if (this.props.selectedWProg == null) {
      alert('No work program selected.');
    } else {
      const my_url = process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/workprogram/get_summary_report_docx/?wprog_number=' + encodeURIComponent(this.props.selectedWProg.value);
      window.open(my_url);
    }
  };

  render() {
    return (
      <div className="animated fadeIn">
        <LoadingOverlay active={this.state.loadingLayerIsActive} spinner text="Loading ...">
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <strong>Related / Assigned Documents</strong>
                </CardHeader>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        <strong>Policies</strong>
                      </NavLink>
                    </NavItem>
                    {/*
                      Additional NavItems can be added here.
                    */}
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                      >
                        <strong>KPCIs</strong>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '6' })}
                        onClick={() => { this.toggle('6'); }}
                      >
                        <strong>KPCI Control Assessment</strong>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}
                      >
                        <strong>Issues</strong>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '7' })}
                        onClick={() => { this.toggle('7'); }}
                      >
                        <strong>Regulations</strong>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.PolicyTable.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.PolicyTable = r; }}
                            data={this.state.related_policies}
                            columns={[
                              { Header: 'ID', accessor: 'Policy_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'Title' },
                              { Header: 'Summary', accessor: 'Summary' },
                              { Header: 'Applicability', accessor: 'Applicability', maxWidth: 110 },
                              {
                                id: 'button', accessor: 'Policy_ID',
                                Cell: ({ value }) => (value ? <button onClick={() => { this.openPolicy(value); }}>Open</button> : <div>not available</div>),
                                maxWidth: 80
                              }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-pol-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Policy-ID" field={row.original.Policy_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.Title} split="2" />
                                          <TwoColLine name="Summary" field={row.original.Summary} split="2" />
                                          <TwoColLine name="History (short)" field={row.original.Short_History} split="2" />
                                          <TwoColLine name="History (long)" field={row.original.History} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="5">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Applicability" field={row.original.Applicability} split="4" />
                                          <TwoColLine name="Business Devision" field={row.original.Business_Devision} split="4" />
                                          <TwoColLine name="Category" field={row.original.Category} split="4" />
                                          <TwoColLine name="State" field={row.original.State} split="4" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="3">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Creation Date" field={row.original.Creation_Date} split="7" />
                                          <TwoColLine name="Modification Date" field={row.original.Modification_Date} split="7" />
                                          <TwoColLine name="Publication Date" field={row.original.Publication_Date} split="7" />
                                          <TwoColLine name="Review Date" field={row.original.Review_Date} split="7" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="4">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Owner Title" field={row.original.Owner_Title} split="7" />
                                          <TwoColLine name="Owner Business Group" field={row.original.Owner_BusinessGroup} split="7" />
                                          <TwoColLine name="Approval Title" field={row.original.Senior_Approval_Title} split="7" />
                                          <TwoColLine name="Approval Business Group" field={row.original.Senior_Approval_BusinessGroup} split="7" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                            onFilteredChange={() => {
                              const filtered_table_data = this.PolicyTable.getResolvedState().sortedData;
                              const policy_applicability_number = _
                                .chain(filtered_table_data)
                                .map(line => line.Applicability)
                                .flatten()
                                .countBy()
                                .value();

                              this.setState({
                                policy_plot: {
                                  applicability_pie: {
                                    labels: _.keys(policy_applicability_number),
                                    datasets: [{
                                      data: _.values(policy_applicability_number),
                                      backgroundColor: default_colors.slice(1, _.size(policy_applicability_number))
                                    }]
                                  }
                                }
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.KPCTable.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.KPCTable = r; }}
                            data={this.state.related_kpc}
                            columns={[
                              { Header: 'ID', accessor: 'KPC_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'KPC_Title' },
                              { Header: 'Description', accessor: 'KPC_Description' },
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-kpc-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="KPC-ID" field={row.original.KPC_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.KPC_Title} split="2" />
                                          <TwoColLine name="Description" field={row.original.KPC_Description} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.KPCITable.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.KPCITable = r; }}
                            data={this.state.related_kpci}
                            columns={[
                              { Header: 'ID', accessor: 'KPCI_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'KPCI_Title' },
                              { Header: 'Summary', accessor: 'KPCI_Description' },
                              { Header: 'Active', accessor: 'Active_KPCI', maxWidth: 80 }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-kpci-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="KPCI-ID" field={row.original.KPCI_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.KPCI_Title} split="2" />
                                          <TwoColLine name="Is Active" field={row.original.Active_KPCI} split="2" />
                                          <TwoColLine name="Description" field={row.original.KPCI_Description} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.IssueTable.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.IssueTable = r; }}
                            data={this.state.related_issues}
                            columns={[
                              { Header: 'ID', accessor: 'Issue_ID', maxWidth: 100 },
                              { Header: 'Title', accessor: 'issue_title' },
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-issue-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Issue-ID" field={row.original.Issue_ID} split="2" />
                                          <TwoColLine name="Title" field={row.original.issue_title} split="2" />
                                          <TwoColLine name="Issue Type" field={row.original.issue_type} split="2" />
                                          <TwoColLine name="Status" field={row.original.Status} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="GCRS Location" field={row.original.Location_Country} split="5" />
                                          <TwoColLine name="GCRS Legal Entity" field={row.original.Location_Legal_Entity} split="5" />
                                          <TwoColLine name="Issue Owner" field={row.original.issue_owner_name} split="5" />
                                          <TwoColLine name="Issue Administrator" field={row.original.issue_administrator_name} split="5" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col lg="6">
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Creation On" field={row.original.Issue_Date_Created} split="7" />
                                          <TwoColLine name="Completion Date" field={row.original.Issue_Completion_Date} split="7" />
                                          <TwoColLine name="Issue Mitigation Date" field={row.original.Issue_Current_Issue_Mitigation_Date} split="7" />
                                          <TwoColLine name="Original Mitigation Date" field={row.original.Issue_Original_Issue_Mitigation_Date} split="7" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.TaxonomyTable.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.TaxonomyTable = r; }}
                            data={this.state.related_taxonomy}
                            columns={[
                              { Header: 'L3-ID', accessor: 'L3_ID', maxWidth: 100 },
                              { Header: 'L3-Title', accessor: 'L3_Title' },
                              { Header: 'L3-Description', accessor: 'L3_Description' },
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                            SubComponent={row => {
                              console.log(row.original);
                              return (
                                <div id="assigned-tax-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="L3-ID" field={row.original.L3_ID} split="2" />
                                          <TwoColLine name="L3-Title" field={row.original.L3_Title} split="2" />
                                          <TwoColLine name="L3-Description" field={row.original.L3_Description} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="L1 and L2 Information" field="" split="2" />
                                          <TwoColLine name="L1-Title" field={row.original.L1_Title} split="2" />
                                          <TwoColLine name="L2-Title" field={row.original.L2_Title} split="2" />
                                          <TwoColLine name="L2-Description" field={row.original.L2_Description} split="2" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.KPCI_Assessment_Table.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.KPCI_Assessment_Table = r; }}
                            data={this.state.related_kpci_assessment}
                            columns={[
                              { Header: 'KPCI ID', accessor: 'KPCI_ID', maxWidth: 90 },
                              { Header: 'Assessment Period', accessor: 'Assessment_Period', maxWidth: 90 },
                              { Header: 'Assessment Type', accessor: 'Assessment_Measure_Name', maxWidth: 150 },
                              { Header: 'Ass. ID', accessor: 'Assessment_ID', maxWidth: 80 },
                              {
                                Header: 'RAG Status', accessor: 'Assessment_Response', maxWidth: 80,
                                getProps: (state, rowInfo) => {
                                  if (rowInfo) {
                                    let background = "";
                                    switch (rowInfo.original.Assessment_Response) {
                                      case 'Green':
                                        background = 'highlight-green';
                                        break;
                                      case 'Amber':
                                        background = 'highlight-amber';
                                        break;
                                      case 'Red':
                                        background = 'highlight-red';
                                        break;
                                      default:
                                        break;
                                    }
                                    return { className: background };
                                  }
                                  return {};
                                }
                              },
                              { Header: 'Justification', accessor: 'Assessment_Justification' },
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                            SubComponent={row => {
                              return (
                                <div id="assigned-kpci-subcomponent">
                                  <Row>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="KPCI-ID" field={row.original.KPCI_ID} split="5" />
                                          <TwoColLine name="KPCI Name" field={row.original.KPCI_Title} split="5" />
                                          <TwoColLine name="Creation Date" field={row.original.Contol_Instance_Create_Date_and_Time} split="5" />
                                          <TwoColLine name="KPCI Description" field={row.original.KPCI_Description} split="5" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                    <Col>
                                      <Card>
                                        <CardBody>
                                          <TwoColLine name="Assessment Details" field="" split="5" />
                                          <TwoColLine name="ID" field={row.original.Assessment_ID} split="4" />
                                          <TwoColLine name="Color" field={row.original.Assessment_Response} split="4" />
                                          <TwoColLine name="Type" field={row.original.Assessment_Measure_Name} split="4" />
                                          <TwoColLine name="Date (Last)" field={row.original.Last_Assessment_Date} split="4" />
                                          <TwoColLine name="Period" field={row.original.Assessment_Period} split="4" />
                                          <TwoColLine name="Justification" field={row.original.Assessment_Justification} split="4" />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
                      <Row id="Excel_icon_row">
                        <Col lg="11"></Col>
                        <Col>
                          <Button block color="link" onClick={() => {
                            const data = this.Regulation_Table.getResolvedState().sortedData.map(x => x._original);
                            downloadCSV({
                              data: data,
                              separator: ';',
                              filename: 'filtered_export.csv',
                            });
                          }}>
                            <img src={Excel_icon} height="20" width="20" id="download_icon" alt="" />
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ReactTable
                            ref={(r) => { this.Regulation_Table = r; }}
                            data={this.state.related_regulation}
                            columns={[
                              { Header: 'Regulation', accessor: 'regulation_title' },
                              {
                                id: 'button', accessor: 'link',
                                Cell: ({ value }) => (value.includes('http') ? <button onClick={() => { this.open_regulation(value); }}>Open</button> : <div>not available</div>),
                                maxWidth: 80
                              }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                              const id = filter.pivotId || filter.id;
                              return row[id] !== undefined
                                ? String(row[id]).toLowerCase().search(filter.value.toLowerCase()) > -1
                                : true;
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {/*
              <Card>
                <CardHeader>
                  <Row>
                    <Col lg="3">
                      <strong>Create and download a generic summary report</strong>
                    </Col>
                    <Col lg="2">
                      <Button block color="primary" type="button" onClick={this.get_summary_report}>Download Docx File</Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              */}
            </Col>
          </Row>
        </LoadingOverlay>
      </div>
    );
  }
}

export default DashboardRelated;
