export default {
  items: [
    {
      title: true,
      name: 'Digital Audit Assistant',
      wrapper: {
        element: '',
        attributes: {}
      },
    },
    {
      name: 'Home',
      url: '/home',
      icon: 'icon-home'
    },

    {
      title: true,
      name: 'VIEW & ASSIGN',
      wrapper: {
        element: '',
        attributes: {}
      },
    },

    {
      name: 'Policy',
      url: '/explore/policy',
      icon: 'icon-folder-alt',
    },

    {
      name: 'Controls',
      url: '/explore/kpci',
      icon: 'icon-folder-alt',
    },
    {
      name: 'Issues',
      url: '/explore/issue',
      icon: 'icon-folder-alt',
    },

    {
      title: true,
      name: 'VISUALIZE & REPORT',
      wrapper: {
        element: '',
        attributes: {}
      },
    },

    {
      name: 'Dashboard',
      url: '/assign/dashboard',
      icon: 'icon-screen-desktop',
    },
    {
      name: 'Network Analysis',
      url: '/explore/network',
      icon: 'icon-shuffle',
    },

    {
      title: true,
      name: 'SUPERUSER',
      wrapper: {
        element: '',
        attributes: {}
      },
    }
  ]
};
