import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  FormText
} from 'reactstrap';

import Select from 'react-select'

import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Excel_icon from '../Icons/download_icon/Excel_icon.png';
import './style.css'
import _ from 'lodash';
import { downloadCSV } from '../CSVExport/CSVExport.js';
import { post } from "../../services/http";
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//const query_get_table_date = process.env.REACT_APP_EXPRESS_PUBLIC_URL + '/api/explore/get_table_date/';

import DateTimePicker from './DateTimePicker';

function isInList(num, list) {
  // List is an Array()
  var result = false;
  for (var i in list) {
    if (num == list[i]) { result = true }
  }
  return result
}


const customControlStyles = base => ({
  height: 20,
});

//Class that defines the search cells
//Has been improved to not use placeholders but instead directly store old searches in the value
class SearchCell extends React.Component {



  constructor() {
    super();

    this.UpdateSearch = this.UpdateSearch.bind(this)

    this.state = {
      value: '',
      downloadProgress: 0,
      isDownloading: false,
    };

  }

  UpdateSearch(event) {

    this.setState({ value: event.target.value })
  }


  componentDidMount() {

    if (this.props.filter) {
      this.setState({ value: this.props.filter.value })
    }
  }

  render() {

    return (
      <Input
        //placeholder to facilitate the search
        placeholder={(this.props.filter) ? this.props.filter.value : ''}
        value={this.state.value}
        onChange={event => this.UpdateSearch(event)}
        onKeyPress={event => {


          if (event.keyCode === 13 || event.which === 13) {
            this.props.onChange(event.target.value)
          }
        }}
      />
    )
  }
}


class TableFrameServerSide extends React.Component {
  constructor(props) {
    super(props);
    this.refReactTable = React.createRef();

    this.state = {
      data: [{}], // use empty object so no data text won't be rendered
      pages: null,
      loading: true,
      columns: [],
      total_rows: 100,
      pageSize: 10,
      from: '',
      to: '',
      filterSelected: 0,
      selectedWProg: null,
      validFrom: '',
      tableDate: '',
      errorMessage: null
    };

    this.fetchData = this.fetchData.bind(this);
    this.requestData = this.requestData.bind(this);
    this.QueryData = this.QueryData.bind(this)
    this.setFromDate = this.setFromDate.bind(this)
    this.setToDate = this.setToDate.bind(this)
    this.getTableDate = this.getTableDate.bind(this)
    this.triggerTableUpdate = this.triggerTableUpdate.bind(this)
  }


  componentDidMount() {

    //temporary fix for the default sort assigned bug: when switching from views(e.g. Policy explorer to Issue explorer),
    //The sorting stays active
    //TODO: find better solution


    this.getTableDate();

  }

  getTableDate() {
    var params = {
      table_name: this.props.tableDate
    }
    var maxdate = '';
    post('/api/explore/get_table_date', params)
      .then((response) => {
        if(response.data &&  response.data.rows ) {
          maxdate = response.data.rows[0][0]['maxdate'];
          var formatDate = new Date(maxdate).toLocaleDateString('en-EN',
            { year: 'numeric', month: 'short', day: 'numeric' });
          this.setState({ tableDate: formatDate });
        }
      })

  }

  triggerTableUpdate() {
    if (this.refReactTable.current) {
      this.fetchData(this.refReactTable.current.state)
    }

  }

  setFromDate(year, month, day) {
    var date = year.toString() + '-' + month.toString() + '-' + day.toString();
    console.log("From date==>", date);
    this.setState({ from: date }, () => {
      var temp_table = this.refReactTable.current.state;
      temp_table.sorted = [];
      console.log("Initial state==>", this.state);
      this.fetchData(temp_table);
    })
    //console.log(date)

    if (this.refReactTable.current) {

      this.fetchData(this.refReactTable.current.state)
    }


  }

  setToDate(year, month, day) {
    var date = year.toString() + '-' + month.toString() + '-' + day.toString()
    this.setState({ to: date })

    if (this.refReactTable.current) {
      this.fetchData(this.refReactTable.current.state)
    }

  }

  fetchData(state) {
    const fromDateExist = !!this.state.from
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
    ).then(res => {
        //temp fix to only change columns if they changed
        //This way, choosing a work program resets all filters
        //if (this.state.columns.map(x => x.Header).length != res.columns.map(x => x.Header).length) {
        var work_program = null
        var final_columns = []

        if (this.state.selectedWProg) {
          work_program = this.state.selectedWProg['value']
        }

        if (res.columns.length > 5) {

          final_columns = "prependColumns" in this.props ? this.props.prependColumns.concat(res.columns) : ("appendColumns" in this.props ? res.columns.concat(this.props.appendColumns) : res.columns)

          if (work_program === null) {
            for (var i = final_columns.length - 1; i >= 0; i--) {
              if (isInList(final_columns[i].Header, ["Relevance", "words_program", "words_item"])) {
                final_columns.splice(i, 1);
              }
            }
          }

          this.setState({ columns: final_columns })
        }
        //}
        var validFrom = '';

        /* if(res.rows.length > 0){
          for(var i = 0; res.rows.length ; i ++){
            console.log(res.rows[i]["Valid From"]);
          }
        } */
        // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        this.setState({
          data: fromDateExist ? res.rows : this.state.data,
          pages: res.pages,
          validFrom: validFrom,
          loading: fromDateExist ? false : true,
          pageSize: state.pageSize
        });
      });
  }


  requestData = (pageSize, page, sorted, filtered) => {
    return new Promise((resolve, reject) => {

      //determine what records to get
      var start = page * pageSize
      var end = (page + 1) * pageSize


      var work_program = null
      if (this.state.selectedWProg) {
        work_program = this.state.selectedWProg['value']
      }
      // Retrieve data from my SQL
      this.QueryData(start, end, filtered, sorted, work_program)
        .then(function (result) {
          //Define column names and search field
          var data = result.data
          var columns = [];

          if (data.length > 0) {
            columns = _.keys(data[0]).map(x => {
              return ({
                Header: x.replace(/_/g, ' '), accessor: x, width: 150, Filter: ({ filter, onChange }) => {
                  return (
                    <div>
                      <SearchCell
                        filter={filter}
                        onChange={onChange}
                      />
                      {/*<Input
                        //placeholder to facilitate the search
                        placeholder={(filter) ? filter.value : ''}

                        onKeyPress={event => {


                          if (event.keyCode === 13 || event.which === 13) {
                            onChange(event.target.value)
                          }
                        }}
                      /> */}
                    </div>
                  )
                },
              })
            });
          }
          //If statusText is a column name, it should not be displayed. it should be displayed as a separate columns
          for (var i = columns.length - 1; i >= 0; i--) {
            //Check if not the wrong columns are deleted, since we are looping and splicing columns variable
            if (isInList(columns[i].Header, ["Relevance", "StatusText", "policy code", "is assigned", "words program", "words item"])) {
              columns.splice(i, 1);
              //console.log('spliced')
            }

            if (work_program === null) {
              if (isInList(columns[i].Header, ["Relevance", "words program", "words item"])) {
                columns.splice(i, 1);
                //console.log('spliced')
              }
            }

          }
          //return the result
          const res = {
            rows: data,
            pages: Math.ceil(result.row_count / pageSize),
            columns: columns,

          };
          resolve(res)
        })
    });
  };


  QueryData(start, end, filtered, sorted, work_program) {
    return new Promise((resolve, reject) => {
        if (this.props.versioning) {
          var manipulations = {
            'start': start, 'end': end, 'filtered': filtered,
            'sorted': sorted, 'from': this.state.from, 'to': this.state.to,
            'work_program': work_program
          }
        }
        else {
          var manipulations = { 'start': start, 'end': end, 'filtered': filtered, 'sorted': sorted }
        }


        var final_query = { 'base_query': this.props.query, 'manipulations': manipulations };
        this.setState({ final_query: final_query });
        //Data Query
        console.log("manipulations==>", manipulations);
        post(this.props.query, manipulations)
        .then(res => {
          if (!res.data) this.setState({ errorMessage: res });
          var columns = [];
          if (res.data?.rows?.length > 0) {
            columns = _.keys(res.data.rows[1]).map(x => { return ({ Header: x.replace(/_/g, ' '), accessor: x, width: 150 }) });
          }
          var length_data = res.data?.rows?.length;
          resolve({ 'data': length_data ? res.data?.rows[length_data - 2] : [], 'row_count': length_data ? res.data?.rows[length_data - 1][0]['FOUND_ROWS()']: [] })

        })
        .catch(function (error) {
          console.log(error);
        })
    })
  }

  onFilterBtnClick(filterSelected) {
    this.setState({
      filterSelected: filterSelected
    });

  }


  setSorted(current_state) {
    var is_set = false
    var new_state = current_state
    if (current_state.length > 0) {
      for (var i = 0; i < current_state.length; i++) {
        if (current_state[i].id == 'is_assigned') {
          new_state[i].desc = true
          is_set = true
        }
      }
    }
    if (is_set == false) {
      new_state.push({ 'id': 'is_assigned', 'desc': true })
    }
    return (new_state)
  }

  changeSelect = (selectedOption) => {

    if (this.props.wprogOptionList.indexOf(selectedOption) > -1 || selectedOption == null) {
      this.setState({
        selectedWProg: selectedOption
      },
        () => {
          if (this.refReactTable.current) {
            var temp_table = this.refReactTable.current.state;
            temp_table.sorted = this.setSorted(temp_table.sorted);
            //console.log(temp_table)
            this.fetchData(temp_table)
          }
        }
      )
    }
  }

  handleDownload = () => {
    this.setState({ isDownloading: true });

    var manipulations = this.state.final_query.manipulations;
    delete manipulations['start'];
    delete manipulations['end'];

    post(this.state.final_query.base_query, manipulations)
      .then(res => {
        downloadCSV({
          data: res.data.rows[1],
          separator: ';',
          filename: 'filtered_export.csv',
        });
        this.setState({ isDownloading: false });
        toast.success('Download complete', { autoClose: 2000 });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isDownloading: false });
        toast.error('Download failed!', { autoClose: 2000 })
      });
  };


  renderDownloadButton = () => {
    if (this.state.isDownloading) {
      return <FaSpinner size={30} spin className='spinner'/>;
    }
    return (
      <Button block color="link" onClick={this.handleDownload}>
        <img className="float-center" src={Excel_icon} height="30" width="30" alt="" />
      </Button>
    );
  };

  render() {
    //console.log(this.props);
    const { data, pages, loading } = this.state;
    return (
      <div className="animated fadeIn">
        <br />
        <Card>
          <CardHeader>
            <Row>
              <Col xs="12" lg="4">
                <Row>
                  <Col>
                    <strong> <div className="mx-auto">{this.props.title}</div></strong>
                    <div><FormText className="help-block">Data as of {this.state.tableDate} {this.props.subtitle}</FormText></div>
                    {/*  <div><FormText className="help-block">{this.props.subtitle}</FormText></div>*/}
                  </Col>
                </Row>
              </Col>
              <Col>
                <FormText className="help-block">Select a work program</FormText>

                <Select

                  value={this.state.selectedWProg}
                  options={this.props.wprogOptionList}
                  onChange={this.changeSelect}
                  //onSelectResetsInput={true}
                  isClearable
                  isSearchable
                  placeholder="Select an Audit Work Program ..."
                />

              </Col>

              <Col lg="4"><div className="float-right">
                <DateTimePicker setFromDate={this.setFromDate}
                  setToDate={this.setToDate}
                  versioning={this.props.versioning} />

              </div></Col>
              <Col xs="12" lg="1">
                <ToastContainer toastClassName="toast-container" />
                  <div className="float-right">{this.renderDownloadButton()}</div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>

            <ReactTable
              columns={this.state.columns}
              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
              data={data}
              noDataText={this.state.errorMessage ? 'Please refresh the page' : 'No Data Available'}
              pages={this.state.pages} // Display the total number of pages
              loading={loading} // Display the loading overlay when we need it
              ref={this.refReactTable}
              onFetchData={this.fetchData} // Request new data when things change
              filterable
              defaultPageSize={10}
              className="-striped -highlight"
              SubComponent={"SubComponent" in this.props ? row => this.props.SubComponent(row) : null}

            />
            <br />
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default TableFrameServerSide


